<template>
  <div :class="b()">
    <template v-if="showResult">
      <div :class="b('search-results')">
        <ul :class="b('search-items')">
          <li v-for="suggestion in suggestions" :key="suggestion.id">
            <router-link :to="getUrlBySuggestion(suggestion)">
              <span class="hint-item" @click="getResultSuggestion(suggestion)">
                <div class="hint-item__container">
                  <span
                    :class="
                      b('emphasis', {
                        left: needLeftMargin(suggestion, elem, idx),
                        right: needRightMargin(suggestion, elem, idx),
                      })
                    "
                    v-for="(elem, idx) in emphasis[suggestion.id]"
                    :key="idx"
                  >
                    <b class="font-weight-bold" v-if="elem.isMarked">
                      {{ elem.value }}
                    </b>
                    {{ elem.isMarked ? "" : elem.value }}
                  </span>
                  <span :class="b('doctor-spec')">{{ suggestion.spec }}</span>
                </div>
              </span>
            </router-link>
          </li>
        </ul>
      </div>
    </template>
    <template v-if="showNotResultInput">
      <div :class="b('search-results')" >
        <ul :class="b('search-items')">
          <li>
            <div v-if="loading">
              <v-layout align-center justify-center>
                <v-progress-circular
                        v-if="loading"
                        indeterminate
                        color="primary"
                ></v-progress-circular>
              </v-layout>
            </div>
            <div v-else>
              <span сlass="overline">Результатов не найдено..</span>
            </div>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import * as names from "../../store/names";
import { mapActions, mapGetters } from "vuex";
import Emphasis from "../../plugins/emphasis";
import isEmpty from "lodash/isEmpty";
const COMPONENT_NAME = "dynamic-link";
export default {
  name: COMPONENT_NAME,
  props: ["data", "type"],
  mixins: [Emphasis],
  data: () => ({
    suggestions: [],
    emphasis: {},
    searchValue: "",
    loading: false,
  }),
  mounted() {
    this.loading = true;
    setTimeout(() => (this.loading = false), 6000);
  },
  computed: {
    ...mapGetters({
      value: names.SEARCH_VALUE,
      selectedCity: names.OBJ_SELECTED_CITY,
    }),
    showResult() {
      return (
        this.suggestions !== null &&
        this.suggestions !== undefined &&
        !isEmpty(this.suggestions) &&
        this.searchValue !== ""
      );
    },
    showNotResultInput() {
      if (this.searchValue !== "" && this.searchValue.length >= 3) {
        if (!isEmpty(this.suggestions)) {
          return false;
        }
      } else {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapActions({
      teleportSearchValue: names.ACTION_TRANSMISSION_SEARCH_VALUE,
      selectResultTemplate: names.ACTION_SELECT_RESULT_TEMPLATE,
    }),
    getUrlBySuggestion(suggestion) {
      return (
        "/search/" +
        this.selectedCity.url +
        "/" +
        suggestion.entity +
        "/" +
        suggestion.id
      );
    },

    async getResultSuggestion(suggestion) {
      this.teleportSearchValue(suggestion.name);
      let entity =
        suggestion.entity.charAt(0).toUpperCase() + suggestion.entity.slice(1);
      await this.selectResultTemplate({
        templateName: `MainResult${entity}`,
        data: suggestion,
      });
      this.searchValue = "";
    },
  },
  watch: {
    data: {
      handler() {
        this.suggestions = this.data.searchHints;
        this.searchValue = this.value;
        this.generateEmphasis((suggestion) => suggestion.name);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~scss/helpers/helpers";

.dynamic-link__search-results {
  z-index: 96 !important;
}
.hint-item {
  cursor: pointer;
  &__container {
    max-width: 792px;
  }
  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
  }
}

.dynamic-link {
  position: relative;
  &__search-results {
    position: absolute;
    display: block;
    background-color: white;
    font-size: 1rem;
    z-index: 99;
    max-height: 350px;
    overflow-y: auto;
    padding: 10px 20px 20px 20px;
    left: 0;
    right: 0;
    border-radius: 2px;
    box-shadow: 0 25px 29px 0 rgba(11, 71, 91, 0.38);
    transition: all 0.5s ease 0s;
    & li {
      padding: 8px 10px;
      border-bottom: 1px solid #f7f7f7;
    }
  }
  &__emphasis {
    color: #000000;
    font-weight: 300;
    text-transform: none;
    white-space: normal;

    &_left {
      margin-left: -4px;
    }

    &_right {
      margin-right: -4px;
    }
  }

  &__search-items {
    list-style: none;
    padding: 5px 0;
  }

  &__doctor-spec {
    color: #a2a2a2;
    text-transform: lowercase;
  }

  &__search-group {
    color: #000000;
    padding: 10px 0 0 10px;
    display: block;
    font-size: 1rem;
    font-weight: 300;
  }

  &__all-result {
    padding-left: 10px;
    text-decoration: underline;
    font-size: 0.9em;
  }
}
</style>
