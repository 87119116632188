<template>
  <v-main>
    <div class="wrapper">
      <v-container>
        <h1>Что вы хотите найти?</h1>
        <!--Навигация-->
        <main-search-navigation />

        <!--Поиск-->
        <v-toolbar color="toolbar">
          <v-text-field
            flat
            solo
            hide-details
            autocomplete="off"
            v-model="searchVal"
            @input="searchSuggest"
            @keyup.esc="stopSuggestions"
            @keydown.enter="searchSuggest"
            :placeholder="searchPlaceholder"
            spellcheck="false"
            ref="inputSearch"
          >
            <template v-slot:append>
              <v-btn icon small @click="stopSuggestions">
                <v-icon small>{{ icons.mdiClose }}</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-toolbar>

        <!--Поисковые подсказки-->
        <main-search-hints ref="refEntityDynamicLink" :data="{ searchHints }" />

        <!--Результаты поиска-->
        <main-search-result v-show="resultTemplateName" />
      </v-container>
    </div>
  </v-main>
</template>

<script>
import * as names from "../../store/names";
import { mapActions, mapGetters } from "vuex";
import { mdiClose } from "@mdi/js";
import debounce from "lodash/debounce";
import MainSearchHints from "./MainSearchHints";
import MainSearchNavigation from "./MainSearchNavigation";
import MainSearchResult from "./MainSearchResult";

export default {
  name: "MainSearchComponent",
  components: { MainSearchResult, MainSearchNavigation, MainSearchHints },
  data: () => ({
    icons: {
      mdiClose,
    },
    value: "",
    isLoading: false,
    search: null,
  }),
  watch: {
    search(val) {
      val && val !== this.searchHints && this.searchSuggest(val);
    },
  },
  computed: {
    ...mapGetters({
      searchHints: names.OBJ_SEARCH,
      searchType: names.SEARCH_TYPE,
      searchPlaceholder: names.SEARCH_PLACEHOLDER,
      searchValue: names.SEARCH_VALUE,
      resultTemplateName: names.RESULT_TEMPLATE_NAME,
      selectedCity: names.OBJ_SELECTED_CITY,
    }),
    searchVal: {
      get() {
        return this.searchValue;
      },
      set(newVal) {
        return this.teleportSearchValue(newVal);
      },
    },
  },
  methods: {
    ...mapActions({
      mainSearch: names.ACTION_MAIN_SEARCH,
      teleportSearchValue: names.ACTION_TRANSMISSION_SEARCH_VALUE,
    }),
    searchSuggest: debounce(async function (event) {
      this.value = event;
      await this.mainSearch({
        cityId: this.selectedCity.id,
        value: this.value,
        type: this.searchType,
      });
    }, 500),
    stopSuggestions() {
      this.teleportSearchValue("");
    },
  },
};
</script>
//test3
<style lang="scss" scoped>
@import "~scss/helpers/helpers";
.wrapper {
  max-width: 1140px;
  width: 100%;
  margin: 25px auto 120px;
  background-image: url("~images/bgimage.webp");
  background-repeat: no-repeat;
  min-height: 327px;
  @include tablet-down {
    margin-bottom: 50px;
  }
  h1 {
    padding: 20px 0;
  }
  .container {
    max-width: 930px;
    h2 {
      padding: 20px 0;
    }
  }
}
</style>
