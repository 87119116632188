<template>
  <div :class="b()">
    <div class="container">
      <div class="theme-title-one text-center">
        <h2 class="main-title">Почему вам понравится на MedvedDoc?</h2>
      </div>
      <v-row class="mt-5">
        <v-col sm="12" md="4" cols="12">
          <v-lazy
            v-model="isActive"
            :options="{
              threshold: 1,
            }"
            min-height="200"
            transition="fade-transition"
          >
            <v-card class="advantages-card" outlined>
              <div class="search-icon icon"></div>
              <div class="card_title">
                <h5 class="title">Удобный поиск</h5>
              </div>
              <div class="card_body">
                <p>
                  Выбирайте специалистов и клиники так, как вам удобно,
                  например, по расположению или цене.
                </p>
              </div>
            </v-card>
          </v-lazy>
        </v-col>
        <v-col sm="12" md="4" cols="12">
          <v-lazy
            v-model="isActive"
            :options="{
              threshold: 1,
            }"
            min-height="200"
            transition="fade-transition"
          >
            <v-card class="advantages-card" outlined>
              <div class="buckshee-icon icon"></div>
              <div class="card_title">
                <h5 class="title">Бесплатный сервис</h5>
              </div>
              <div class="card_body">
                <p>
                  Пользуйтесь MedvedDoc совершенно бесплатно, а также получайте
                  скидки от наших партнеров.
                </p>
              </div>
            </v-card>
          </v-lazy>
        </v-col>
        <v-col sm="12" md="4" cols="12">
          <v-lazy
            v-model="isActive"
            :options="{
              threshold: 1,
            }"
            min-height="200"
            transition="fade-transition"
          >
            <v-card class="advantages-card" outlined>
              <div class="smart-icon icon"></div>
              <div class="card_title">
                <h5 class="title">Smart-корзина</h5>
              </div>
              <div class="card_body">
                <p>
                  Применяйте корзину для подбора клиники, если вы планируете
                  получить несколько услуг.
                </p>
              </div>
            </v-card>
          </v-lazy>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdvantagesComponent",
  data: () => ({
    isActive: false,
  }),
};
</script>

<style lang="scss" scoped>
.advantages-card {
  height: 350px;
  width: 100%;
  min-width: 240px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  margin: auto;
  text-align: center;
  overflow: hidden;
  &:hover {
    box-shadow: 0 2px 6px 10px #e3ecfc;
    background: linear-gradient(-45deg, #c4d5d1, #23a6d5, #0044cc, #0500cc);
    background-size: 400% 400%;
    animation: gradient 5s ease infinite;
    color: #fff;

    .icon {
      background-color: initial;
    }

    .card_body {
      color: #ffffff;
    }
  }
  .icon {
    pointer-events: none;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    top: 50px;
    right: 60px;
    background-color: $primary-color;
    @include tablet-down {
      margin: auto;
    }
  }
  .card_title {
    line-height: 34px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    z-index: 10;
    .title {
      font-weight: 900;
      font-size: 1.8rem !important;
      padding: 15px 0;
      font-family: $sanspro !important;
    }

    @include tablet-down {
      font-size: 28px;
      flex-direction: column;
    }
  }

  .card_body {
    text-align: center;
    position: relative;
    z-index: 10;
  }
}

.search-icon {
  mask: url(~icons/search-icon.svg) no-repeat center / contain;
  -webkit-mask: url(~icons/search-icon.svg) no-repeat center / contain;
}

.smart-icon {
  mask: url(~icons/smart-basket-icon.svg) no-repeat center / contain;
  -webkit-mask: url(~icons/smart-basket-icon.svg) no-repeat center / contain;
}

.buckshee-icon {
  mask: url(~icons/buckshee-icon.svg) no-repeat center / contain;
  -webkit-mask: url(~icons/buckshee-icon.svg) no-repeat center / contain;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
