<template>
  <div class="popular-specialization" v-if="objPopularSpecialization">
    <v-container>
      <h2 class="ml-3 text-center">
        Популярные специализации в {{ objCityNames.cities_services }}
      </h2>
      <v-lazy
        v-model="isActive"
        :options="{
          threshold: 1,
        }"
        min-height="200"
        transition="fade-transition"
      >
        <div class="d-flex align-center justify-center">
          <v-chip-group column active-class="text-primary">
            <v-chip
              v-for="(tag, index) in objPopularSpecialization"
              outlined
              label
              :href="
                '/search/' +
                selectedCity.url +
                '/specialization/' +
                tag.specialization_id
              "
              color="primary"
              :key="index"
            >
              {{ tag.name }}
            </v-chip>
          </v-chip-group>
        </div>
      </v-lazy>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as names from "store/names";

export default {
  name: "PopularSpecializationComponent",
  mounted() {
    this.getPopularSpecialization({ cityId: this.selectedCity.id });
  },
  data: () => ({
    isActive: false,
  }),
  computed: {
    ...mapGetters({
      selectedCity: names.OBJ_SELECTED_CITY,
      objPopularSpecialization: names.OBJ_POPULAR_SPECIALIZATION,
      objCityNames: names.OBJ_CITY_NAMES,
    }),
  },
  methods: {
    ...mapActions({
      getPopularSpecialization: names.ACTION_GET_POPULAR_SPECIALIZATION,
    }),
  },
};
</script>

<style scoped lang="scss">
.popular-specialization {
  margin-bottom: 60px;

  h2 {
    margin-bottom: 40px;
  }
}
</style>
